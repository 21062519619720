.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
  padding: 40px;
  background-color: #121212;
  color: #eee;
  height: 90vh; /* This is fine */
  overflow: auto; /* Allow scroll */
}

.leftPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
    gap: 25px;
}

.flag {
  width: 100%;
  height: auto;
  max-width: 300px;
  border-radius: 10px;
}

.coat {
  width: 100%;
  height: auto;
  max-width: 250px;
  border-radius: 10px;
}

.statsBox {
  background-color: #1f1f1f;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.25);
}

.statsBox h3 {
  color: var(--earf-blue);
  margin-bottom: 10px;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title {
  font-size: 32px;
  color: var(--earf-blue);
  margin-bottom: 0;
}

.motto {
  font-size: 1rem;
  color: #aaa;
  margin-top: 0;
  margin-bottom: 20px;
}

.section {
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.25);
}

.section h2 {
  font-size: 20px;
  color: var(--earf-blue);
  margin-bottom: 10px;
}

.section p,
.section li {
  font-size: 16px;
  color: #ccc;
}

.section ul {
  padding-left: 20px;
  margin: 0;
}

a {
  color: var(--earf-blue);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.loader {
  padding: 40px;
  text-align: center;
  font-size: 1.2rem;
  color: var(--earf-blue);
}

.error {
  padding: 40px;
  text-align: center;
  font-size: 1.2rem;
  color: #ff6b6b;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
    padding: 20px;
  }


  .leftPanel {
    order: 2; /* move flag/coat/stats to bottom */
    align-items: flex-start;
    margin-top: 30px;
  }

  .rightPanel {
    order: 1; /* make this first */
  }

  .flag,
  .coat {
    max-width: 90%;
    margin: 0 auto;
  }

  .statsBox {
    max-width: 100%;
    width: 100%;
  }

  .title {
    font-size: 24px;
    text-align: left;
  }

  .section h2 {
    font-size: 18px;
  }

  .section {
    padding: 15px;
  }

  .section p,
  .section li {
    font-size: 14px;
  }

  .statsBox {
    max-width: 100%;
  }
}
